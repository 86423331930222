.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 1100px;
  width: 100%;
  margin: 25px 0px;
}

.section h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-big);
  color: var(--text-color);
  text-align: center;
  padding: 10px 0px;
}

.section h2 {
  font-family: var(--font-family);
  font-size: var(--font-size-large);
  font-weight: bold;
  color: var(--text-color);
  text-align: center;
  padding: 10px 0px;
}

.section p {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
  text-align: center;
}

.separator {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 5px;
}

.segment {
  flex-grow: 1;
  background-color: var(--secondary-color);
  opacity: 0.5;
  margin: 0 5px;
  border-radius: 10px;
}

.segment.active {
  background-color: var(--primary-color);
  opacity: 1;
}

.tutorialImage {
  margin: 20px 0px;
}

@media (max-width: 1000px) {
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 1100px;
    width: 85%;
    margin: 25px 0px;
  }
}
