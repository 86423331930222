.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 1100px;
  width: 100%;
  margin: 15px 0px;
}

.section h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-large);
  font-weight: bold;
  text-decoration: underline solid;
  color: var(--text-color);
}

.section h2 {
  font-family: var(--font-family);
  font-size: var(--font-size-large);
  font-weight: bold;
  color: var(--text-color);
}

.section h3 {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  font-weight: bold;
  color: var(--text-color);
}

.section h4 {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
}

.sectionHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.sectionHeader img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.sectionHeader h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-big);
  color: var(--text-color);
  text-align: center;
  padding: 10px 0px;
}

.sectionSeparator {
  width: 700px;
  height: 5px;
  background: var(--text-color);
  border-radius: 10px;
  opacity: 0.75;
}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0px;
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  font-weight: bold;
  color: var(--text-color);
  text-align: center;
  width: 100px;
  height: 100px;
  padding: 10px;
  margin: 10px;
  box-shadow: var(--shadow-color) 0px 54px 55px, var(--shadow-color) 0px -12px 30px, var(--shadow-color) 0px 4px 6px, var(--shadow-color) 0px 12px 13px, var(--shadow-color) 0px -3px 5px;
  border-radius: 20px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.card:hover {
  transform: scale(1.05); /* Aumenta ligeiramente o tamanho ao passar o mouse */
}

/* CALCULATOR */
.calculator {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 20px;
}

.inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100px;
  width: 40%;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.calculateButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
  color: var(--secondary-light-color);
  font-family: var(--font-family);
  font-size: var(--font-size-small);
  padding: 10px 10px;
  border-radius: var(--border-radius);
}

.outputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100px;
  width: 40%;
}

@media (max-width: 1000px) {
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 25px 0px;
  }

  .sectionSeparator {
    width: 80%;
    height: 5px;
    background: var(--text-color);
    border-radius: 10px;
    opacity: 0.75;
  }

  .cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    padding: 10px 0px;
  }

  .card {
    width: 100%;
  }

  .calculator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 20px;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100px;
    width: 80%;
  }

  .outputs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100px;
    width: 80%;
  }
}
