.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1100px;
  width: 100%;
  height: 75vh;
  margin: 25px 0px;
}

.forgotPassword {
  display: block;
  width: 100%;
  text-align: right; /* Alinha o texto para a direita */
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--primary-color);
  cursor: pointer;
}
