.bodyHome {
  display: flex;
  flex-direction: column;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

/* HEADER */

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-color);
  padding: 20px 50px;
  box-shadow: var(--shadow-color) 0px 10px 10px -5px;
  z-index: 999;
}

.headerLeft {
  display: flex;
  flex-direction: row;
}

.headerRight {
  display: flex;
  flex-direction: row;
}

  /* HEADER LEFT LOGO */

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.logoImage {
  width: 50px;
  height: 50px;
}

.logo h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-large);
  font-weight: bold;
  color: var(--text-color);
  margin-left: 10px;
}

.logo h1 span {
  font-family: var(--font-family);
  font-size: var(--font-size-large);
  font-weight: bold;
  color: var(--primary-color);
}

.arrow {
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.arrowOpen {
  margin-left: 8px;
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

  /* HEADER LEFT NAVBAR */

.tabs {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
}

.tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px;
  border: none;
  cursor: pointer;
  color: var(--text-light-color);
  font-family: var(--font-family);
  font-size: var(--font-size-base);
}

.tab:hover {
  color: var(--primary-color);
}

.selected {
  color: var(--primary-color);
  font-weight: bold;
}

.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 150px;
  background: var(--background-color);
  box-shadow: var(--shadow-color) 0px 5px 15px;
  padding: 10px;
  border-radius: var(--border-radius);
  z-index: 1000;
  white-space: nowrap;
}

.popup ul {
  list-style-type: none;
}

.popupItem {
  padding: 10px;
  color: var(--text-light-color);
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  font-weight: normal;
}

.popupItemSelected {
  padding: 10px;
  color: var(--text-light-color);
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  font-weight: bold;
}

.popup ul li:hover {
  color: var(--primary-color);
}

  /* HEADER RIGHT SETTINGS */

.mobileMenu {
  display: none;
}

.tabLogin {
  background: var(--primary-color);
  color: var(--secondary-light-color);
  border-radius: var(--border-radius);
  padding: 5px 20px;
  margin-right: 20px;
  cursor: pointer;
}

.tabLogin:hover {
  color: var(--primary-dark-color);
}

.tabUser {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  color: var(--primary-color);
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  cursor: pointer;
}

.mobileTabUser {
  display: none;
}

.mobileTabLogin {
  display: none;
}

/* MAIN */

.mainHome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  background-color: var(--background-color); /* Tom sutil de azul claro */
  margin: 0px;
  border: none;
}

.section {
  display: flex;
  justify-content: center;
  width: 100%;
}

.homepageButton {
  background: var(--primary-color);
  color: var(--secondary-light-color);
  border-radius: var(--border-radius);
  padding: 5px 20px;
  margin-right: 20px;
  cursor: pointer;
  margin: 10px 0px;
}

  /* MAIN HEADER */

.containerHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: none;
  max-width: 1100px;
  width: 100%;
  margin: 20px 0px;
}

.containerHeader h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-big);
  color: var(--text-color);
  text-align: center;
  padding: 10px 200px;
}

.containerHeader p {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
  text-align: center;
  padding: 10px 100px;
}

.headerCards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0px;
}

.headerCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  font-size: var(--font-size-small);
  font-weight: bold;
  color: var(--text-color);
  text-align: center;
  width: 125px;
  height: 125px;
  padding: 10px;
  box-shadow: var(--shadow-color) 0px 54px 55px, var(--shadow-color) 0px -12px 30px, var(--shadow-color) 0px 4px 6px, var(--shadow-color) 0px 12px 13px, var(--shadow-color) 0px -3px 5px;
  border-radius: 20px;
}

  /* MAIN CALCULATORS */

.containerCalculators {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: none;
  max-width: 1100px;
  width: 100%;
}

.calculatorsText {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.calculatorsText h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-big);
  color: var(--text-color);
  text-align: center;
}

.calculatorsText p {
  font-family: var(--font-family);
  font-size: var(--font-size-large);
  color: var(--text-color);
  text-align: center;
}

.calculatorsImage {
  width: 300px;
  height: 300px;
}

  /* MAIN SOFTWARES */

.containerSoftwares {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  border: none;
  max-width: 1100px;
  width: 100%;
}

.softwaresText {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.softwaresText h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-big);
  color: var(--primary-color);
  text-align: center;
}

.softwaresText p {
  font-family: var(--font-family);
  font-size: var(--font-size-large);
  color: var(--primary-light-color);
  text-align: center;
}

.softwaresImage {
  width: 300px;
  height: 300px;
}

  /* MAIN OTHERS */

.containerOthers {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: none;
  max-width: 1100px;
  width: 100%;
  margin: 50px;
}

.othersText {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}

.othersText h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-large);
  color: var(--text-color);
  text-align: center;
  padding: 10px 0px;
}

.othersText p {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
  text-align: center;
}

/* FOOTER */
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  padding: 20px;
  box-shadow: var(--shadow-color) 0px -10px 10px -5px;
}

.footer a {
  margin: 0 10px;
  color: var(--text-color);
}

.footer i {
  font-size: var(--font-size-large);
  cursor: pointer;
}

@media (max-width: 1400px) {
  .logoImage {
    width: 30px;
    height: 30px;
    margin: 10px 0px;
  }

  .logo h1 {
    font-family: var(--font-family);
    font-size: var(--font-size-base);
    font-weight: bold;
    color: var(--text-color);
    margin-left: 10px;
  }

  .logo h1 span {
    font-family: var(--font-family);
    font-size: var(--font-size-base);
    font-weight: bold;
    color: var(--primary-color);
  }

  .tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px;
    border: none;
    cursor: pointer;
    color: var(--text-light-color);
    font-family: var(--font-family);
    font-size: var(--font-size-small);
  }

  .tabUser {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5px;
    color: var(--primary-color);
    font-family: var(--font-family);
    font-size: var(--font-size-small);
    cursor: pointer;
  }
}

@media (max-width: 1000px) {
  .menuOpenBodyHome {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }

  .hideMain {
    display: none;
  }

  /* HEADER */
  .tabsOpen {
    display: flex;
    position: absolute;
    right: 0;
    top: 90px;
    bottom: 67px;
    width: 100%;
    height: 80;
    background: var(--background-dark-color);
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0px;
    z-index: 50;
  }

  .tabs {
    display: none;
  }

  .tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px;
    border: none;
    cursor: pointer;
    color: var(--text-light-color);
    font-family: var(--font-family);
    font-size: var(--font-size-base);
  }

  .tab.selected {
    font-weight: bold;
    color: #007bff;
  }

  .popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: var(--background-color);
    right: 0;
    width: 100%;
    border-radius: 0px
  }

  .mobileMenu {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .tabUser{
    display: none;
  }

  .tabLogin{
    display: none;
  }

  .mobileTabUser {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mobileTabUser span {
    color: var(--primary-color);
    margin-right: 20px;
  }

  .mobileTabLogin {
    display: flex;
    background: var(--primary-color);
    color: var(--secondary-light-color);
    border-radius: var(--border-radius);
    padding: 5px 20px;
  }

  /* MAIN */

  .mainHome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    background-color: var(--background-color); /* Tom sutil de azul claro */
    margin: 0px;
    border: none;
  }

  .section {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .homepageButton {
    background: var(--primary-color);
    color: var(--secondary-light-color);
    border-radius: var(--border-radius);
    padding: 5px 20px;
    margin-right: 20px;
    cursor: pointer;
    margin: 10px 0px;
  }

    /* MAIN HEADER */

  .containerHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 25px;
  }

  .containerHeader h1 {
    font-family: var(--font-family);
    font-size: var(--font-size-big);
    color: var(--text-color);
    text-align: center;
    padding: 0px;
  }

  .containerHeader p {
    font-family: var(--font-family);
    font-size: var(--font-size-base);
    color: var(--text-color);
    text-align: center;
    padding: 0px;
  }

  .headerCards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px 0px;
  }

  .headerCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family);
    font-size: 7px;
    font-weight: bold;
    color: var(--text-color);
    text-align: center;
    width: 60px;
    height: 60px;
    padding: 5px;
    box-shadow: var(--shadow-color) 0px 54px 55px, var(--shadow-color) 0px -12px 30px, var(--shadow-color) 0px 4px 6px, var(--shadow-color) 0px 12px 13px, var(--shadow-color) 0px -3px 5px;
    border-radius: 20px;
  }

    /* MAIN CALCULATORS */

  .containerCalculators {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
  }

  .calculatorsText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .calculatorsText h1 {
    font-family: var(--font-family);
    font-size: var(--font-size-large);
    color: var(--text-color);
    text-align: center;
  }

  .calculatorsText p {
    font-family: var(--font-family);
    font-size: var(--font-size-base);
    color: var(--text-color);
    text-align: center;
  }

  .calculatorsImage {
    width: 150px;
    height: 150px;
  }

    /* MAIN SOFTWARES */

  .containerSoftwares {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
  }

  .softwaresText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .softwaresText h1 {
    font-family: var(--font-family);
    font-size: var(--font-size-large);
    color: var(--primary-color);
    text-align: center;
  }

  .softwaresText p {
    font-family: var(--font-family);
    font-size: var(--font-size-base);
    color: var(--primary-light-color);
    text-align: center;
  }

  .softwaresImage {
    width: 150px;
    height: 150px;
  }

    /* MAIN OTHERS */

  .containerOthers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: none;
    width: 100%;
    margin: 0px;
    padding: 20px;
  }

  .othersText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
