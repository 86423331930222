.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 1100px;
  width: 100%;
  margin: 25px 0px;
}

.section h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-big);
  color: var(--text-color);
  text-align: center;
  padding: 10px 0px;
}

.separator {
  width: 700px;
  height: 5px;
  background: var(--text-color);
  border-radius: 10px;
  opacity: 0.75;
}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  font-size: var(--font-size-small);
  font-weight: bold;
  color: var(--text-color);
  text-align: center;
  width: 345px;
  height: 345px;
  padding: 10px;
  margin: 10px;
  box-shadow: var(--shadow-color) 0px 54px 55px, var(--shadow-color) 0px -12px 30px, var(--shadow-color) 0px 4px 6px, var(--shadow-color) 0px 12px 13px, var(--shadow-color) 0px -3px 5px;
  border-radius: 20px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.card:hover {
  transform: scale(1.1); /* Aumenta ligeiramente o tamanho ao passar o mouse */
}

.cardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cardHeader img {
  width: 50px;
  height: 50px;
}

.cardHeader h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  font-weight: bold;
  color: var(--text-color);
  margin-left: 10px;
}

.cardHeaderFreeTag {
  background: green;
  color: var(--secondary-light-color);
  font-size: var(--font-size-small);
  padding: 2px 5px;
  border-radius: var(--border-radius);
  margin-left: 10px;
}

.cardHeaderPaidTag {
  background: var(--primary-color);
  color: var(--secondary-light-color);
  font-size: var(--font-size-small);
  padding: 2px 5px;
  border-radius: var(--border-radius);
  margin-left: 10px;
}

.cardContent {
  color: #275175;
  padding-top: 20px;
  padding-left: 20px;
  font-size: 16px;
}

.cardContent ul li {
  font-family: var(--font-family);
  font-size: var(--font-size-small);
  color: var(--text-color);
  font-weight: normal;
  margin: 15px 0px;
}

.cardContentNotice {
  background: var(--primary-color);
  color: var(--secondary-light-color);
  font-size: var(--font-size-small);
  padding: 10px; /* Espaçamento interno */
  margin-top: 20px; /* Espaçamento superior */
  border-radius: var(--border-radius); /* Borda arredondada */
}

.disabledCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  font-size: var(--font-size-small);
  font-weight: bold;
  color: var(--text-color);
  text-align: center;
  width: 345px;
  height: 345px;
  padding: 10px;
  margin: 10px;
  box-shadow: var(--shadow-color) 0px 54px 55px, var(--shadow-color) 0px -12px 30px, var(--shadow-color) 0px 4px 6px, var(--shadow-color) 0px 12px 13px, var(--shadow-color) 0px -3px 5px;
  border-radius: 20px;
  transition: transform 0.3s ease;
  cursor: pointer;
  opacity: 0.5; /* Meio transparente */
  pointer-events: none; /* Desabilita cliques */
  cursor: not-allowed; /* Muda o cursor para indicar que está desabilitado */
}

@media (max-width: 1000px) {
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 25px 0px;
  }

  .separator {
    width: 80%;
    height: 5px;
    background: var(--text-color);
    border-radius: 10px;
    opacity: 0.75;
  }

  .cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    padding: 10px 0px;
  }

  .card {
    width: 100%;
  }

  .disabledCard {
    width: 100%;
  }
}
