.bodyFundars {
  display: flex;
  flex-direction: row;
  background: var(--background-color);
  height: 100vh;
  width: 100%;
}

h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-large);
  font-weight: bold;
  color: var(--text-color);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* PROJECT */

.mainProject {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  margin: 200px 300px;
}

.projectHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0px;
}

.projectSection {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  overflow-y: visible;
  box-shadow: var(--shadow);
  border-radius: 30px;
  width: 100%;
}

.projectSearch {
  display: flex;
  flex-direction: row;
}

.projectButtons {
  display: flex;
  flex-direction: row;
}

.projectFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 10px 0px;
}

/* GEOTECHNICS */

.mainGeotechnics {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh; /* Garante que a grid ocupe a altura total da tela */
  padding: 50px; /* Substitui margin por padding para evitar overflow */
  box-sizing: border-box; /* Inclui padding nas dimensões totais */
}

.geotechnicsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.geotechnicsMenu {
  display: flex;
  flex-direction: column;
}

.geotechnicsStep {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 3px 0px 3px 0px;
}

.geotechnicsSection {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  overflow-y: visible;
  margin: 20px 0px 20px 0px;
}

.geotechnicsFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.geotechnicsFooterElement {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* SOLUTION */
.mainStructure {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  margin: 50px 50px;
}

.sectionGeotechnics {
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100%;
}

.sectionGeotechnicsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sectionGeotechnicsSection {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  overflow-y: visible;
}

.sectionGeotechnicsFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sectionStructure {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  margin-left: 25px;
}

.structureHeader {
  display: flex;
  flex-direction: column;
}

.structureSection {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  overflow-y: visible;
}

.structureFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.structureSectionRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 5px 0px;
}

.structureSectionElement {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.structureModalSection {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  overflow-y: visible;
  max-height: 457px;
}

/* CONFIG */

.mainConfig {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  margin: 50px 50px;
}

.configMenu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 20px 0px;
}

.configSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
