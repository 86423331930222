.selectComponent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.componentText {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
}

.componentElements {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
  margin-left: 5px;
  background: var(--background-dark-color);
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius);
}

.componentUnit {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
  margin-left: 5px;
}
