.outputComponent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.componentText {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
}

.componentValue {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
  font-weight: bold;
  margin-left: 5px;
}

.componentUnit {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
  margin-left: 5px;
}
