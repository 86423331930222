.bodyQuantitars {
  display: flex;
  flex-direction: row;
  background: var(--background-color);
  height: 100vh;
}

/* MODEL */

.mainModel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.mainModel h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  font-weight: normal;
  color: var(--text-color);
  text-align: center;
  padding: 10px 0px;
}

.fileInput {
  display: none;
}

/* COMPOSING */

.mainComposing {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  flex-grow: 1;
  padding: 50px;
}

/* TABLE */

.mainTable {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  flex-grow: 1;
  padding: 50px;
}

.mainButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

@media (max-width: 768px) {
  .bodyQuantitars {
    display: flex;
    flex-direction: column;
    background: var(--background-color);
    width: 100%;
    height: 100vh;
  }

  /* MODEL */
  .mainModel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    padding: 0px 20px;
  }
}
