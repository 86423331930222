.bodyComplas {
  display: flex;
  flex-direction: row;
  background: var(--background-color);
  height: 100vh;
}

/* WORK */

.mainWork {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  margin: 200px 300px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0px;
}

.section {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  overflow-y: visible;
  box-shadow: var(--shadow);
  border-radius: 30px;
  width: 100%;
}

.search {
  display: flex;
  flex-direction: row;
}

.buttons {
  display: flex;
  flex-direction: row;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 10px 0px;
}

.modalTable {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  overflow-y: visible;
  max-height: 195px;
  padding: 10px 0px;
}

/* ENTRY */

.mainEntry {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  margin: 50px 50px;
}

.trCustomSubTable {
  display: flex;
  grid-template-columns: none;
  flex-direction: row;
  width: 100%;
  box-shadow: none;
}

.customSubTable {
  width: 100%;
}

.customSubTable thead {
  width: 100%;
  background: var(--background-dark-color);
}

.customSubTable thead tr {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  box-shadow: none;
  align-items: center;
}

.customSubTable tbody tr {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  box-shadow: none;
  align-items: center;
}

.customSubTable th {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
  font-weight: bold;
}

.customSubTable td {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
  background: var(--background-dark-color);
}

.thDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.thHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.td {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
  background: var(--background-dark-color);
  width: 100%;
  height: 100%;
}

.th {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
  background: var(--background-dark-color);
  width: 100%;
  height: 100%;
}
