.navbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  height: 100vh;
  padding: 25px 0px;
  border-radius: 0px 50px 50px 0px;
  box-shadow: var(--shadow);
  z-index: 999;
  background: var(--background-color);
}

.icon {
  width: 50px;
}

.main {
  display: flex;
  flex-direction: column;
}

.button {
  margin: 10px 0px;
}

.button:hover {
  color: var(--accent-color);
}

@media (max-width: 768px) {
  .navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    padding: 0px 25px;
    border-radius: 0px 00px 50px 50px;
    box-shadow: var(--shadow);
    z-index: 999;
    background: var(--background-color);
  }

  .main {
    display: flex;
    flex-direction: row;
  }

  .button {
    margin: 0px 10px;
  }
}
