
.free {
  bottom: -15px;
  right: -15px;
  background: green;
  color: white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  font-size: var(--font-size-small);
  font-weight: bold;
  text-transform: uppercase;
  padding: 2px 5px;
  border-radius: var(--border-radius);
  cursor: default;
}

.student {
  bottom: -15px;
  right: -20px;
  background: var(--primary-color);
  color: white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  font-size: var(--font-size-small);
  font-weight: bold;
  text-transform: uppercase;
  padding: 2px 5px;
  border-radius: var(--border-radius);
  cursor: default;
}

.trial {
  bottom: -15px;
  right: -20px;
  background: var(--primary-color);
  color: white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  font-size: var(--font-size-small);
  font-weight: bold;
  text-transform: uppercase;
  padding: 2px 5px;
  border-radius: var(--border-radius);
  cursor: default;
}

.basic {
  bottom: -15px;
  right: -20px;
  background: #CD7F32;
  color: white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  font-size: var(--font-size-small);
  font-weight: bold;
  text-transform: uppercase;
  padding: 2px 5px;
  border-radius: var(--border-radius);
  cursor: default;
}

.pro {
  bottom: -15px;
  right: -10px;
  background: #BFC0C0;
  color: white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  font-size: var(--font-size-small);
  font-weight: bold;
  text-transform: uppercase;
  padding: 2px 5px;
  border-radius: var(--border-radius);
  cursor: default;
}

.premium {
  bottom: -15px;
  right: -20px;
  background: #ecc900;
  color: white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  font-size: var(--font-size-small);
  font-weight: bold;
  text-transform: uppercase;
  padding: 2px 5px;
  border-radius: var(--border-radius);
  cursor: default;
}

.tester {
  bottom: -15px;
  right: -20px;
  background: #6A0DAD;
  color: white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  font-size: var(--font-size-small);
  font-weight: bold;
  text-transform: uppercase;
  padding: 2px 5px;
  border-radius: var(--border-radius);
  cursor: default;
}
