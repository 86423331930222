.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 1100px;
  width: 100%;
  margin: 20px;
}

.section h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-large);
  color: var(--text-color);
  text-align: center;
  padding: 10px 0px;
}

.section h2 {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
  text-align: center;
  padding: 10px 0px;
}

.subscription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 1100px;
  width: 100%;
}

.subscription h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-large);
  color: var(--text-color);
  text-align: center;
  padding: 10px 0px;
}

.subscription h2 {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
  text-align: center;
  padding: 10px 0px;
}

.textActive {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  font-weight: bold;
  color: green;
  text-align: center;
  padding: 10px 0px;
}

.textCanceled {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: red;
  font-weight: bold;
  text-align: center;
  padding: 10px 0px;
}

.textInactive {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: #6C757D;
  font-weight: bold;
  text-align: center;
  padding: 10px 0px;
}

.textIncomplete {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: orange;
  text-align: center;
  padding: 10px 0px;
}

@media (max-width: 1000px) {
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 1100px;
    width: 85%;
    margin: 25px 0px;
  }

  .subscription {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 1100px;
    width: 100%;
  }
}
