:root {
  /* Primary Colors */
  --primary-color: #007bff; /* Azul escuro */
  --primary-light-color: #48a0fd; /* Azul moderado */
  --primary-dark-color: #0F172A; /* Azul ainda mais escuro */

  /* Secondary Colors */
  --secondary-color: #64748B; /* Cinza médio */
  --secondary-light-color: #CBD5E1; /* Cinza claro */
  --secondary-dark-color: #334155; /* Cinza escuro */

  /* Accent Colors */
  --accent-color: #14B8A6; /* Verde água */
  --accent-light-color: #67E8F9; /* Azul claro para realces */

  /* Background Colors */
  --background-color: #F8FAFC; /* Quase branco para fundo */
  --background-dark-color: #D6EAF8; /* Branco puro para contrastes */

  /* Text Colors */
  --text-color: #1E293B; /* Cor principal do texto */
  --text-light-color: #475569; /* Cor secundária do texto */
  --text-dark-color: #0F172A; /* Cor do texto em fundo claro */

  /* Border and Shadow */
  --border-color: #E2E8F0; /* Bordas suaves */
  --shadow-color: rgba(0, 0, 0, 0.1); /* Sombra leve */
  --shadow: var(--shadow-color) 0px 5px 5px, var(--shadow-color) 0px -5px 5px, var(--shadow-color) 0px 5px 5px, var(--shadow-color) 0px 5px 5px, var(--shadow-color) 0px -5px 5px;

  /* Typography */
  --font-family: "Rubik", sans-serif; /* Fonte principal */
  --font-size-big: 35px;
  --font-size-large: 24px;
  --font-size-base: 16px;
  --font-size-small: 12px;

  /* Spacing */
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 24px;
  --border-radius: 20px;

  /* Transition */
  --transition-duration: 0.3s;
  --transition-function: ease-in-out;
}

[data-theme="dark"] {
  /* Background Colors */
  --background-color: #0F0F0F;
  --background-dark-color: #1E1E1E;

  /* Text Colors */
  --text-color: #E0E0E0;
  --text-light-color: #B0B0B0;
  --text-dark-color: #F0F0F0;

  /* Border and Shadow */
  --border-color: #334155; /* Bordas suaves, mas mais escuras */
  --shadow-color: rgba(0, 0, 0, 0.5); /* Sombra mais pronunciada */
}
