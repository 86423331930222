.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 1100px;
  width: 100%;
  margin: 25px 0px;
}

.section h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-big);
  color: var(--text-color);
  text-align: center;
  padding: 10px 0px;
}

.products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0px;
}

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  font-size: var(--font-size-small);
  font-weight: bold;
  color: var(--text-color);
  text-align: center;
  width: 345px;
  height: 500px;
  padding: 10px;
  margin: 10px;
  box-shadow: var(--shadow-color) 0px 54px 55px, var(--shadow-color) 0px -12px 30px, var(--shadow-color) 0px 4px 6px, var(--shadow-color) 0px 12px 13px, var(--shadow-color) 0px -3px 5px;
  border-radius: 20px;
}

.productHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.productHeaderIcon {
  position: relative;
  display: inline-block;
}

.productHeaderIcon img {
  width: 65px;
  height: 65px;
}

.productHeader h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-large);
  font-weight: bold;
  color: var(--text-color);
  margin-left: 10px;
}

.productContent {
  color: #275175;
  padding-top: 20px;
  padding-left: 20px;
  font-size: 16px;
}

.productContentItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px;
}

.productContentItem h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-small);
  color: var(--text-color);
  font-weight: normal;
  margin: 0px 10px;
  text-align: left;
}

.productFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.productFooterPrice {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.productFooterPrice h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-large);
  color: var(--text-color);
  text-align: center;
}

.productFooterPrice h2 {
  font-family: var(--font-family);
  font-size: var(--font-size-small);
  color: gray;
  text-align: center;
}

@media (max-width: 1000px) {
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 1100px;
    width: 100%;
    margin: 25px 0px;
  }

  .products {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    padding: 10px 0px;
  }
}
