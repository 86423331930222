.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 1100px;
  width: 100%;
  margin: 25px 0px;
}

.section h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-big);
  color: var(--text-color);
  text-align: center;
  padding: 10px 0px;
}

.section h2 {
  font-family: var(--font-family);
  font-size: var(--font-size-large);
  font-weight: bold;
  color: var(--text-color);
  text-align: center;
  margin-top: 20px;
}

.section p {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--text-color);
  text-align: center;
}

.question {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.question h1 {
  color: var(--text-color);
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  font-weight: bold;
}

.question p {
  color: var(--text-color);
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  text-align: left;
}

@media (max-width: 1000px) {
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 1100px;
    width: 85%;
    margin: 25px 0px;
  }
}
