.colorMode {
  display: flex;
  align-items: center; /* Alinha verticalmente o texto e o botão */
}

.colorModeText {
  color: white;
  font-size: var(--fs_3); /* Ajuste o tamanho da fonte conforme necessário */
  font-weight: bold; /* Deixa o texto mais destacado */
  padding-right: 10px;
  cursor: default;
}

.colorModeButton {
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  padding: 0px;
}

.colorModeImage {
  color: white;
  width: 25px; /* Ajuste o tamanho da imagem como desejar */
  height: 25px;
  transition: transform 0.3s ease; /* Efeito de transição suave */
}
